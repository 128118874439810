export async function apiPost(endpoint: string, body: any = {}) {
  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  return response
}

export async function apiGet(endpoint: string) {
  const response = await fetch(endpoint, {
    method: 'GET',
  })
  return response
}

export async function extractApiError(response: Response) {
  const jsonBody = await response.json()
  if ('error' in jsonBody) {
    return jsonBody['error'] as string
  }
  return ''
}
