import type { Theme, ToastContent, TypeOptions } from 'react-toastify'
import { toast } from 'react-toastify'
import ToastContainer from './Toast'

interface ToastOptions {
  autoClose?: number | false
  content: ToastContent
  theme?: Theme
  type?: TypeOptions
  toastId?: string
  isHTML?: boolean
  closeOnClick?: boolean
  style?: any
}

function defaultToast(options: ToastOptions) {
  const {
    content,
    type,
    autoClose = 5000,
    theme = 'colored',
    toastId,
    isHTML = false,
    closeOnClick = true,
    style = {},
  } = options
  const toastOptions = {
    autoClose,
    theme,
    toastId,
    isHTML,
    closeOnClick,
    style,
  }

  const toastContent = content

  switch (type) {
    case 'info':
      return toast.info(toastContent, toastOptions)
    case 'success':
      return toast.success(toastContent, toastOptions)
    case 'warning':
      return toast.warn(toastContent, toastOptions)
    case 'error':
      return toast.error(toastContent, toastOptions)
    default:
      return toast.info(toastContent, toastOptions)
  }
}

export { defaultToast as toast, ToastContainer }
